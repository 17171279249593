<template>
  <span>{{ humanCode }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

// A map for predefined codes and their human-readable equivalents
const codeMap: { [key: string] : string} = {
  'loading-data-request': 'Loading Data Request',
};

@Component({
  components: {},
})
export default class FlightEventCode extends Vue {
  @Prop() code!: string;

  // Computed property to convert the passed code into a human-readable format
  get humanCode() {
    // If it's predefined in the codeMap, return the mapped value
    if (codeMap[this.code]) {
      return codeMap[this.code];
    }

    // Otherwise, convert the code to a human-readable format
    return this.code
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
</script>