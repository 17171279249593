<!-- eslint-disable max-len -->
<template>
  <div class="about text-center">
    <div id="nav" class="mt-0 pt-3 pb-2 bg-white border-bottom">
      <h3 class="m-0">Your Dashboard</h3>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container text-left">
        <div class="mb-4 row justify-content-between">
          <div class="row m-0 p-0">
            <div class="col col-md-2 text-left">
              <h3 class="mb-0">
                <img
                    :src="avatar.imageUrl($store.state.auth.user, { size: 40 })"
                    alt="Avatar"
                    class="rounded-circle"
                    width="60"
                  >
              </h3>
            </div>
            <div class="col-md-auto text-left">
              <h3 class="mb-0">
                <div>
                  <span class="ml-2">
                    {{ user.name }}
                    <small class="text-muted">({{ user.username }})</small>
                  </span>
                  <div class="h6">
                    <span
                      v-if="user && user.stations && user.stations.length > 0"
                      class="badge badge-warning text-white mx-2 px-2"
                      >
                      feeder
                    </span>
                    <span v-else class="badge badge-info text-white mx-2 px-2">
                      observer
                    </span>
                    <span
                      v-if="user.role === 'admin'"
                      class="badge badge-danger text-white mr-2 px-2">
                      admin
                    </span>
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col col-lg-3 text-right">
            <router-link to="/user/settings" class="mr-2 btn btn-primary">Settings</router-link>

            <b-button v-b-modal.modal-api-key>API Key</b-button>
            <b-modal ok-only id="modal-api-key" title="API Key">
              <div class="mb-4 mt-2 text-muted">
                Your API key is unique and allows you to access the API. Every
                API key has its own set of permissions and limits. Sharing an API key with
                others will reduce your available quota.
              </div>
              <div class="my-2 mb-3 font-weight-bold">
                API Key
              </div>
              <div class="mb-4 mt-2">
                <span class="border border-grey bg-light rounded p-2">
                  {{ $store.state.auth.user.apiKey }}
                </span>
              </div>
              <div class="my-2 font-weight-bold">
                Subscription Tier
              </div>
              <div class="my-2 mb-4 h5">
                <span class="badge badge-primary">
                  Free
                </span>
              </div>
              <div class="my-2 font-weight-bold">
                Quota
              </div>
              <div class="my-2 mb-4">
                Details coming soon.
              </div>
              <div class="my-2 font-weight-bold">
                Documentation
              </div>
              <div class="my-2">
                Details coming soon.
              </div>
            </b-modal>
          </div>
        </div>
        <h4 class="mb-4">Ground Stations</h4>
        <div v-if="user && user.stations && user.stations.length > 0">
          <p class="text-muted">
            You have added <strong>{{ user.stations.length }} ground
            stations</strong> to your account. Your stations have fed a total of
            <strong>{{ textFormatter.intToHuman(tallyStationMessageCounts(user.stations)) }} messages</strong>
            to Airframes.
          </p>

          <table class="table table-hover mb-4 bg-white">
            <tr>
              <th>ID</th>
              <th>Ident</th>
              <th>Type</th>
              <th>Nearest Airport</th>
              <th>Heard Messages</th>
              <th>Status</th>
              <th>Last Report</th>
            </tr>
            <tbody>
              <tr v-for="station in user.stations"
                :key="`station-table-row-${station.id}`"
                @click="handleStationClick(station)"
                style="cursor: pointer"
              >
                <td>
                  <span class="badge badge-primary text-white pl-2 pr-2">
                    {{ station.id }}
                  </span>
                </td>
                <td>
                  {{ station.ident }}
                </td>
                <td>
                  <span v-if="station.sourceType">
                    {{ station.sourceType.toUpperCase() }}
                  </span>
                  <span v-else class="text-muted">
                    UNKNOWN
                  </span>
                </td>
                <td>
                  <span v-if="station.nearestAirportIcao">
                    {{ station.nearestAirportIcao.toUpperCase() }}
                  </span>
                  <span v-else class="text-muted">
                    UNKNOWN
                  </span>
                </td>
                <td class="text-center">
                  <span v-if="station.messagesCount" class="badge badge-pill badge-info">
                    {{ textFormatter.intToHuman(station.messagesCount) }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="mr-1 p-1 badge" :class="statusBadgeClass(station.status)" >
                    {{ humanize(station.status) }}
                  </span>
                </td>
                <td>
                  {{ station.lastReportAt | moment("from", "now") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p class="text-muted">
            You have not added any ground stations to your account.
          </p>
        </div>

        <b-button
          v-b-modal.modal-add-ground-station
          variant="outline-secondary"
          >Add Ground Station</b-button>
        <b-modal ok-only id="modal-add-ground-station" title="Add Ground Station">
          <p class="my-2">
            Add a ground station to your account via a helpful wizard.
          </p>
          <form name="edit_station_form" @submit.prevent="handleStationCreate">
            <b-form-group label="Type">
              <b-form-select
                name="sourceType"
                v-model="newStation.sourceType"
                :options="sourceTypes"
                aria-label="Type"
                v-on:change="handleSourceTypeChange"
                :state="sourceTypeState"
                aria-describedby="sourceType-feedback"
                >
              </b-form-select>
              <b-form-invalid-feedback id="sourceType-feedback">
                Select a valid station type
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Nearest Airport (4-digit ICAO)">
              <b-form-input
                id="nearestAirportIcao"
                type="text"
                class="form-control"
                placeholder="KSMF"
                v-model="newStation.nearestAirportIcao"
                aria-label="Nearest Airport (4-digit ICAO)"
                aria-describedby="nearestAirportIcao-feedback"
                :state="nearestAirportState"
                v-on:change="handleNearestAirportIcaoChange"
                ></b-form-input>
              <b-form-invalid-feedback id="nearestAirportIcao-feedback">
                Enter exactly 4 alphanumeric characters
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Ident">
              <b-form-input
                id="ident"
                type="text"
                placeholder="KE-KMHR1"
                class="form-control"
                v-model="newStation.ident"
                aria-label="Ident"
                aria-describedby="ident-feedback"
                :state="identState"
                v-on:change="handleIdentChange"
                ></b-form-input>
              <b-form-invalid-feedback id="ident-feedback">
                Enter at least 4 alphanumeric characters
              </b-form-invalid-feedback>
            </b-form-group>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';

import Avatar from '@/utilities/avatar';
import { TextFormatter } from '@/utilities/text_formatter';

@Component({
  components: {
  },
})
export default class UserDashboard extends Vue {
  textFormatter = TextFormatter;

  fetchInterval?: number;

  user: any = {};

  newStation = {
    ident: '',
    sourceType: '',
    nearestAirportIcao: '',
  };

  sourceTypes = [
    { value: '', text: 'Select a Station Type' },
    { value: 'acars-acarsdec', text: 'ACARS - acarsdec' },
    { value: 'vdl-vdlm2dec', text: 'VDL - vdlm2dec' },
    { value: 'vdl-dumpvdl2', text: 'VDL - dumpvdl2' },
    { value: 'hfdl-dumphfdl', text: 'HFDL - dumphfdl' },
    { value: 'satcom-iridium', text: 'SATCOM - Iridium' },
    { value: 'satcom-inmarsat-acars', text: 'SATCOM - Inmarsat ACARS' },
    { value: 'satcom-inmarsat-adsc', text: 'SATCOM - Inmarsat ADS-C' },
    { value: 'marine-aiscatcher', text: 'AIS - aiscatcher' },
  ];

  avatar = Avatar;

  get identState() {
    return this.newStation.ident.length >= 4;
  }

  get nearestAirportState() {
    return this.newStation.nearestAirportIcao.length === 4;
  }

  get sourceTypeState() {
    return this.newStation.sourceType !== '';
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.refresh();
    }
  }

  mounted() {
    this.refresh();
    this.clearIntervals();
    this.fetchInterval = window.setInterval(() => {
      this.refresh();
    }, 10000);
  }

  clearIntervals() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  fetchUser() {
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/users/username/${this.$store.state.auth.user.username}`,
      method: 'GET',
    }).then((response) => {
      this.user = response.data;
    });
  }

  refresh() {
    this.fetchUser();
  }

  statusBadgeClass(status: string) { // eslint-disable-line class-methods-use-this
    if (status === 'active') return 'badge-success';
    if (status === 'inactive') return 'badge-danger';
    return 'badge-info';
  }

  handleStationClick(station: any) {
    this.$router.push(`/user/station/${station.id}`);
  }

  handleIdentChange() {
    console.log('handleIdentChange', this.newStation.ident);
  }

  handleNearestAirportIcaoChange() {
    console.log('handleNearestAirportIcaoChange', this.newStation.nearestAirportIcao);
    this.handleUpdateIdent();
  }

  handleSourceTypeChange() {
    console.log('handleSourceTypeChange', this.newStation.sourceType);
    this.handleUpdateIdent();
  }

  handleUpdateIdent() {
    console.log('handleUpdateIdent', this.newStation.ident);
    if (this.newStation.sourceType) {
      let initials = '';
      if (this.$store.state.auth.user.name !== '') {
        if (this.$store.state.auth.user.name.includes(' ')) {
          const names = this.$store.state.auth.user.name.split(' ');
          initials = `${names[0].charAt(0)}${names[1].charAt(0)}`;
        } else {
          initials = this.$store.state.auth.user.name.slice(0, 2);
        }
      } else {
        initials = 'XX';
      }
      const nearestAirportIcao = this.newStation.nearestAirportIcao || 'YYYY';
      const sourceType = this.newStation.sourceType.split('-')[0].toLocaleUpperCase();
      this.newStation.ident = `${initials}-${nearestAirportIcao}-${sourceType}`;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleStationCreate() {
    console.log('Create station');
  }

  humanize(str: string) { // eslint-disable-line class-methods-use-this
    const frags = str.split('-');
    for (let i = 0; i < frags.length; i += 1) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  tallyStationMessageCounts(stations: any) { // eslint-disable-line class-methods-use-this
    let total = 0;
    for (let i = 0; i < stations.length; i += 1) {
      if (stations[i] && stations[i].messagesCount)
        total += stations[i].messagesCount;
    }
    return total;
  }

  beforeDestroy() {
    this.clearIntervals();
  }

  beforeUnmount() {
    this.clearIntervals();
  }

  destroyed() {
    this.clearIntervals();
  }

  unmounted() {
    this.clearIntervals();
  }
}
</script>
