<template>
  <div>
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3>Airports</h3>
      <router-link to="/airports">Airports</router-link>
      <router-link :to="`/airports/${$route.params.id}`">
        | {{ airport ? airport.icao : 'Loading...' }}
      </router-link>
    </div>

    <div v-if="fetchingAirport" class="m-4 p-4 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="noAirportFound" class="m-4 p-4 text-center">
      <h1>404</h1>
      <h2>Airport Not Found</h2>
    </div>

    <div v-if="airport" class="about mt-4 text-center">
      <div class="mb-4 p-4 border-bottom">
        <div class="container text-left">
          <h3 class="mb-1">
            {{ airport.name }}
            <div class="pull-right" style="margin-top: -6px;">
              <small class="badge text-white p-2 pl-2 pr-2" :class="airportStatusClass()">
                {{ textFormatter.humanize(airport.status) }}
              </small>
            </div>
          </h3>
          <div class="mb-4 text-xs text-muted">
            ID: {{ airport.id }}
          </div>

          <div class="row mb-4">
            <div class="col-5">
              <table class="table table-hover mb-0 bg-white">
                <tr>
                  <td>ICAO</td>
                  <td>{{ airport.icao }}</td>
                </tr>
                <tr>
                  <td>IATA</td>
                  <td>{{ airport.iata }}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{{ textFormatter.humanize(airport.type) }}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{{ airport.municipality }}</td>
                </tr>
                <tr>
                  <td>Region</td>
                  <td>{{ airport.region }}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>
                    <img
                      v-if="countryFlags.flagUrlByCountryName(airport.country)"
                      :src="countryFlags.flagUrlByCountryName(airport.country)"
                      width="30" height="20" :title="`${airport.country} Flag`"
                      alt="Country Flag"
                      class="mr-1"
                      />
                    {{ airport.country }}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ textFormatter.humanize(airport.status) }}</td>
                </tr>
              </table>
            </div>
            <div class="col-7">
              <Map
                :coordinates="airportCoordinates()"
                :initialZoom="airportZoomLevel()"
                class="border"
                style="height: 100%; width: 100%;"
                />
            </div>
          </div>
        </div>
      </div>

      <div class="mb-4 p-4 border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <h5>Arrivals</h5>
              <table class="table">
                <thead>
                  <tr>
                    <td class="text-left">Flight</td>
                    <td class="text-left">From</td>
                    <td class="text-left">Gate</td>
                    <td class="text-left">Time</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="flight in flightsArriving" :key="`flight-arriving-${flight.id}`">
                    <td class="text-left">
                      <router-link :to="`/flights/${flight.id}`">
                        {{ flight.flight }}
                      </router-link>
                    </td>
                    <td class="text-left">
                      <router-link v-if="flight.departingAirport" :to="`/airports/${flight.departingAirport}`">
                        {{ flight.departingAirport }}
                      </router-link>
                    </td>
                    <td class="text-left">
                      <span v-if="flight.arrivalTimeScheduled">
                        {{ flight.arrivalTimeScheduled }}
                      </span>
                      <span v-else class="text-muted">
                        Unknown
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="flight.arrivalGateScheduled">
                        {{ flight.arrivalGateScheduled }}
                      </span>
                      <span v-else class="text-muted">
                        Unknown
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-6">
              <h5>Departures</h5>
              <table class="table">
                <thead>
                  <tr>
                    <td class="text-left">Flight</td>
                    <td class="text-left">To</td>
                    <td class="text-left">Gate</td>
                    <td class="text-left">Time</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="flight in flightsDeparting" :key="`flight-departing-${flight.id}`">
                    <td class="text-left">
                      <router-link :to="`/flights/${flight.id}`">
                        {{ flight.flight }}
                      </router-link>
                    </td>
                    <td class="text-left">
                      <router-link v-if="flight.destinationAirport" :to="`/airports/${flight.destinationAirport}`">
                        {{ flight.destinationAirport }}
                      </router-link>
                    </td>
                    <td class="text-left">
                      <span v-if="flight.departureTimeScheduled">
                        {{ flight.departureTimeScheduled }}
                      </span>
                      <span v-else class="text-muted">
                        Unknown
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="flight.departureGateScheduled">
                        {{ flight.departureGateScheduled }}
                      </span>
                      <span v-else class="text-muted">
                        Unknown
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';
import { CountryFlags } from '@/utilities/country_flags';
import { TextFormatter } from '@/utilities/text_formatter';

import Map from '@/components/shared/Map.vue';

Vue.use(VueAxios, axios);

@Component({
  components: {
    Map,
  },
})
export default class AirportDetail extends Vue {
  airport: any = null;

  errorFetchingAirport = false;

  errorFetchingAirportMessage = '';

  fetchingAirport = false;

  fetchingFlights = false;

  flightsArriving: any[] = [];

  flightsDeparting: any[] = [];

  noAirportFound = false;

  countryFlags = CountryFlags;

  textFormatter = TextFormatter;

  mounted() {
    this.airport = null;
    this.refresh();
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.airport = null;
      this.refresh();
    }
  }

  refresh() {
    if (this.$route.params) {
      this.fetchingAirport = true;
      console.log('Fetching airport detail...');
      Vue.axios.get(`${this.$store.state.apiServerBaseUrl}/airports/icao/${this.$route.params.id}`).then((response) => {
        console.log('Fetched airport detail.');
        console.log(response.data);
        const airport = response.data;
        this.airport = airport;
        this.fetchingAirport = false;
        this.fetchFlights();
      }).catch((error) => {
        console.error('Error fetching airport detail.');
        console.error(error);
        this.errorFetchingAirport = true;
        this.errorFetchingAirportMessage = error;
        this.fetchingAirport = false;
      });
    }
  }

  airportCoordinates() {
    const coords = { latitude: this.airport.latitude, longitude: this.airport.longitude };
    return coords;
  }

  airportStatusClass() {
    const statusClass = {
      'badge-success': this.airport.status === 'open',
      'badge-warning': this.airport.status === 'pending-activation',
      'badge-danger': this.airport.status === 'inactive',
    };
    return statusClass;
  }

  airportZoomLevel() {
    let zoom = 3;
    switch (this.airport.type) {
    case 'heliport':
      zoom = 17;
      break;
    case 'large_airport':
      zoom = 12;
      break;
    case 'medium_airport':
      zoom = 13;
      break;
    case 'small_airport':
      zoom = 14;
      break;
    default:
      zoom = 3;
    }
    return zoom;
  }

  async fetchFlights() {
    this.fetchingFlights = true;
    let headers = {};
    if (this.$store.state.auth
        && this.$store.state.auth.user
        && this.$store.state.auth.user.apiKey
    ) {
      headers = {
        'X-API-KEY': this.$store.state.auth.user.apiKey,
      };
    }
    let response = await axios.get(`${this.$store.state.apiServerBaseUrl}/flights/active?arr_airport=${this.airport.icao}`, {
      headers,
    });
    let results = response.data;
    console.log('Fetched flights arriving', results);
    this.fetchingFlights = false;
    this.flightsArriving = results;

    this.fetchingFlights = true;
    response = await axios.get(`${this.$store.state.apiServerBaseUrl}/flights/active?dep_airport=${this.airport.icao}`, {
      headers,
    });
    results = response.data;
    console.log('Fetched flights departing', results);
    this.fetchingFlights = false;
    this.flightsDeparting = results;
  }

}
</script>
