<template>
  <div>
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3>Flights</h3>
      <router-link to="/flights">Live</router-link>
       |
      <span v-if="noFlightFound">
        Flight Not Found
      </span>
      <span v-else-if="$route.params.id">
        <router-link :to="`/flights/${$route.params.id}`">
          Flight {{ flight ? flight.flight : 'Loading...' }}
        </router-link>
      </span>
    </div>
    <div v-if="fetchingFlight" class="m-4 p-4 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="noFlightFound" class="m-4 p-4 text-center">
      <h1>404</h1>
      <h2>Flight Not Found</h2>
    </div>
    <div v-else-if="rateLimited" class="m-4 p-4 text-center">
      <h1>429</h1>
      <h2>Rate Limited</h2>
      <h5>Please wait a few moments and try again.</h5>
    </div>

    <div v-if="flight" class="mt-4 container text-left">
      <table class="table table-condensed-xs table-bordered">
        <thead>
          <tr class="bg-dark text-light">
            <th class="d-none d-sm-table-cell">ID</th>
            <th class="text-center">Airline</th>
            <th class="text-center">Flight</th>
            <th class="d-none d-sm-table-cell text-center">Airframe</th>
            <th class="text-center">
              <span class="d-xs d-sm-none">Dep</span>
              <span class="d-none d-sm-inline">Departure</span>
            </th>
            <th class="text-center">Arrival</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white">
            <td class="d-none d-sm-table-cell align-middle">{{ flight.id }}</td>
            <td class="align-middle text-center">
              <div v-if="airline && airline.id">
                <img
                  :src="`https://media.githubusercontent.com/media/airframesio/airline-images/refs/heads/main/radarbox_banners/${airline.icao}.png`"
                  height="30" max-width="200" :alt="`${airline.name} Logo`" />
              </div>
            </td>
            <td class="p-0 text-center align-middle">
              <table v-if="flight.flight_icao && flight.flight_iata" class="m-0 border-0 table table-sm w-full">
                <td class="text-center border-right border-top-0 border-left-0 border-bottom-0">
                  <div>
                    {{ flight.flight_icao }}
                  </div>
                  <div class="text-muted small">ICAO</div>
                </td>
                <td class="text-center border-0">
                  <div>
                    {{ flight.flight_iata }}
                  </div>
                  <div class="text-muted small">IATA</div>
                </td>
              </table>
              <div v-else-if="flight.flight_icao">
                {{ flight.flight_icao }}
              </div>
              <div v-else-if="flight.flight_iata">
                {{ flight.flight_iata }}
              </div>
              <div v-else>
                {{ flight.flight }}
              </div>
            </td>
            <td class="d-none d-sm-table-cell text-center align-middle">
              <span v-if="airframe && airframe.tail && airframe.id">
                <router-link :to="`/airframes/${airframe.id}`">
                  {{ airframe.tail }}
                </router-link>
              </span>
              <div v-else-if="fetchingFlightEvents" class="text-cente align-middle">
                <div class="spinner-border small" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </td>
            <td class="text-center align-middle">{{ flight.departing_airport }}</td>
            <td class="text-center align-middle">{{ flight.destination_airport  }}</td>
            <td class="align-middle">
              {{ textFormatter.humanize(flight.status) }}
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="flight && flight.latitude && flight.longitude" style="height: 200px;" class="mb-4 border">
        <FlightMap :flight="flight" />
      </div>

      <div class="mb-4">
        <h5>
          Events ({{ events.length }})
          <span v-if="fetchingFlightEvents">
            <b-spinner small label="Small Spinner"></b-spinner>
          </span>
        </h5>
        <table
          v-if="events && events.length > 0"
          class="mb-4 table table-bordered table-striped table-hover table-sm small">
          <thead class="thead-light">
            <tr>
              <th class="text-left">Timestamp</th>
              <th class="text-left">Code</th>
              <th class="text-left">Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in events" :key="`fd-message-event-${event.id}`">
              <td class="text-left text-nowrap align-middle">
                <span :title="event.eventAt">
                  {{ event.event_at | moment("MMM DD YYYY, HH:mm:ss") }}
                </span>
              </td>
              <td class="text-left text-nowrap align-middle"><FlightEventCode :code="event.code" /></td>
              <td class="text-left align-middle"><FlightEventDetails :details="event.details" :event="event" /></td>
            </tr>
          </tbody>
        </table>
        <div v-else-if="fetchingFlightEvents && events.length == 0" class="text-left">
          Loading...
        </div>
        <div v-else-if="errorFetchingFlightEvents" class="text-left">
          <p>{{ errorFetchingFlightEventsMessage }}</p>
        </div>
        <div v-else>
          <p>No events found.</p>
        </div>
      </div>

      <h5>
        Messages ({{ messages.length }})
        <span v-if="fetchingFlightMessages">
            <b-spinner small label="Small Spinner"></b-spinner>
          </span>
      </h5>
      <div v-if="messages.length > 0">
        <table class="mb-4 table table-bordered table-striped table-hover table-sm small">
          <thead class="thead-dark">
            <tr>
              <th class="text-left">Timestamp</th>
              <th class="text-center">Source</th>
              <th class="text-center">Label</th>
              <th class="text-center">Block</th>
              <th class="text-center">Msg #</th>
              <th class="text-center">Position</th>
              <th class="text-left">Text</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in messages"
              :key="`fd-message-${message.id}`"
              >
              <td class="text-left align-middle">
                <router-link :to="`/messages/${message.id}`">
                  <span :title="message.received_at">
                    {{ message.received_at | moment("MMM DD YYYY, HH:mm:ss") }}
                  </span>
                </router-link>
              </td>
              <td class="text-center align-middle">{{ message.source_type.toUpperCase() }}</td>
              <td class="text-center align-middle">{{ message.label }}</td>
              <td class="text-center align-middle">{{ message.block_id }}</td>
              <td class="text-center align-middle">{{ message.message_number }}</td>
              <td class="text-left align-middle">
                <span v-if="message.latitude && message.longitude">
                  {{ message.latitude }}, {{ message.longitude }}
                </span>
              </td>
              <td class="text-wrap text-break w-50">{{ message.text }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="fetchingFlightMessages" class="text-left">
        Loading...
      </div>
      <div v-else-if="errorFetchingFlightMessages" class="text-left">
        <p>{{ errorFetchingFlightMessagesMessage }}</p>
      </div>
      <div v-else>
        <p>No messages found.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';

import FlightEventDetails from '@/components/flights/FlightEventDetails.vue';
import FlightEventCode from '@/components/flights/FlightEventCode.vue';
import FlightMap from '@/components/flights/FlightMap.vue';
import { TextFormatter } from '@/utilities/text_formatter';

Vue.use(VueAxios, axios);

@Component({
  components: {
    FlightEventDetails,
    FlightEventCode,
    FlightMap
  },
})
export default class FlightDetail extends Vue {
  fetchInterval?: number;

  flight: any = null;

  airframe: any = null;

  airline: any = null;

  events: any[] = [];

  messages: any[] = [];

  fetchingAirline = false;

  errorFetchingAirline = false;

  errorFetchingAirlineMessage = '';

  fetchingAirframe = false;

  errorFetchingAirframe = false;

  errorFetchingAirframeMessage = '';

  fetchingFlight = false;

  errorFetchingFlight = false;

  errorFetchingFlightMessage = '';

  fetchingFlightEvents = false;

  errorFetchingFlightEvents = false;

  errorFetchingFlightEventsMessage = '';

  fetchingFlightMessages = false;

  errorFetchingFlightMessages = false;

  errorFetchingFlightMessagesMessage = '';

  noFlightFound = false;

  rateLimited = false;

  textFormatter = TextFormatter;

  mounted() {
    this.flight = null;
    this.refresh();
    this.clearIntervals();
    this.fetchInterval = window.setInterval(() => {
      this.refresh(false);
    }, 15000);
  }

  clearIntervals() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.flight = null;
      this.refresh();
    }
  }

  refresh(progressIndicator: boolean = true) {
    this.fetchFlight(progressIndicator);
  }

  async fetchAirframe(progressIndicator: boolean = true) {
    if (this.flight && !!this.flight.airframe_id) {
      this.fetchingAirframe = progressIndicator;
      console.log('Fetching airframe...', this.flight);
      const url = `https://toad.airframes.io/v1/aircraft/airframes/${this.flight.airframe_id}`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched airframe.');
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingAirframe = false;
          this.errorFetchingAirframe = true;
          this.errorFetchingAirframeMessage = response.data.message;
        } else {
          this.airframe = response.data.airframe;
          this.fetchingAirframe = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingAirframe = false;
      });
    }
  }

  async fetchAirline(progressIndicator: boolean = true) {
    if (this.flight && !!this.flight.airline_id) {
      this.fetchingAirline = progressIndicator;
      console.log('Fetching airline...', this.flight);
      const url = `https://toad.airframes.io/v1/aircraft/airlines/${this.flight.airline_id}`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched airline.');
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingAirline = false;
          this.errorFetchingAirline = true;
          this.errorFetchingAirlineMessage = response.data.message;
        } else {
          this.airline = response.data.airline;
          this.fetchingAirline = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingAirline = false;
      });
    }
  }

  async fetchFlight(progressIndicator: boolean = true) {
    // this.flight = null;
    if (this.$route.params) {
      this.fetchingFlight = progressIndicator;
      console.log('Fetching flight detail...');
      const url = `https://toad.airframes.io/v1/aircraft/flights/${this.$route.params.id}`;
      Vue.axios.get(url).then(async (response) => {
        console.log('Fetched flight', response.data);
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingFlight = false;
          this.errorFetchingFlight = true;
          this.errorFetchingFlightMessage = response.data.message;
          if (response.data.error.status == 404)
            this.noFlightFound = true;
          if (response.data.error.status == 429)
            this.rateLimited = true;
          return;
        }
        const { flight } = response.data;
        this.flight = flight;
        this.fetchingFlight = false;
        if (!this.airframe)
          await this.fetchAirframe();
        if (!this.airline)
          await this.fetchAirline();
        await this.fetchEvents();
        await this.fetchMessages();
        this.noFlightFound = false;
        this.rateLimited = false;
      }).catch((error) => {
        console.error(error);
        this.fetchingFlight = false;
        if (error.status == 404)
          this.noFlightFound = true;
        if (error.status == 429)
          this.rateLimited = true;
      });
    }
  }

  async fetchEvents(progressIndicator: boolean = true) {
    if (this.flight) {
      this.fetchingFlightEvents = progressIndicator;
      console.log('Fetching flight events...');
      const url = `https://toad.airframes.io/v1/aircraft/flights/${this.flight.id}/events`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched flight events.');
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingFlightEvents = false;
          this.errorFetchingFlightEvents = true;
          this.errorFetchingFlightEventsMessage = response.data.message;
        } else {
          this.events = response.data.events;
          this.fetchingFlightEvents = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingFlightEvents = false;
      });
    }
  }

  async fetchMessages(progressIndicator: boolean = true) {
    if (this.flight) {
      this.fetchingFlightMessages = progressIndicator;
      console.log('Fetching flight messages...');
      const url = `https://toad.airframes.io/v1/aircraft/flights/${this.flight.id}/messages`;
      Vue.axios.get(url).then((response) => {
        console.log('Fetched flight messages.');
        if (response.data.error) {
          console.error(response.data.error);
          this.fetchingFlightMessages = false;
          this.errorFetchingFlightMessages = true;
          this.errorFetchingFlightMessagesMessage = response.data.message;
        } else {
          // sort by timestamp
          this.messages = response.data.messages.sort((a: any, b: any) => (a.received_at > b.received_at ? -1 : 1));
          // this.messages = response.data.messages;
          this.fetchingFlightMessages = false;
        }
      }).catch((error) => {
        console.error(error);
        this.fetchingFlightMessages = false;
      });
    }
  }
}
</script>

<style lang="css" scoped>
@media (max-width: 768px) {
  .table-condensed-xs > thead > tr > th,
  .table-condensed-xs > tbody > tr > th,
  .table-condensed-xs > tfoot > tr > th,
  .table-condensed-xs > thead > tr > td,
  .table-condensed-xs > tbody > tr > td,
  .table-condensed-xs > tfoot > tr > td {
    padding: 4px;
  }
}
</style>
