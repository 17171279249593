<template>
  <div v-if="event.code == 'loading-data-request'">
    <div>{{ parsedDetails?.arrival_airport }} → {{ parsedDetails?.departure_airport }}
      <b-button id="raw-details-popover-1" variant="link" size="sm" @click="togglePopover">
        {{ popoverShow ? 'Hide' : 'Show' }} Raw Details
      </b-button>
      <b-popover :target="'raw-details-popover-1'" triggers="click blur" :show.sync="popoverShow" placement="auto">
        <template #title>
          Raw Details
        </template>
        <pre>{{ JSON.stringify(parsedDetails, null, 2) }}</pre>
      </b-popover>
    </div>
  </div>
  <div v-else-if="event.code == 'gate-request'">
    <div>{{ parsedDetails?.arrival_airport }} → {{ parsedDetails?.departure_airport }}
      <b-button id="raw-details-popover-2" variant="link" size="sm" @click="togglePopover">
        {{ popoverShow ? 'Hide' : 'Show' }} Raw Details
      </b-button>
      <b-popover :target="'raw-details-popover-2'" triggers="click blur" :show.sync="popoverShow" placement="auto">
        <template #title>
          Raw Details
        </template>
        <pre>{{ JSON.stringify(parsedDetails, null, 2) }}</pre>
      </b-popover>
    </div>
  </div>
  <div v-else-if="event.code == 'form-entry'">
    <div>{{ parsedDetails?.arrival_airport }} → {{ parsedDetails?.departure_airport }}
      <b-button id="raw-details-popover-3" variant="link" size="sm" @click="togglePopover">
        {{ popoverShow ? 'Hide' : 'Show' }} Raw Details
      </b-button>
      <b-popover :target="'raw-details-popover-3'" triggers="click blur" :show.sync="popoverShow" placement="auto">
        <template #title>
          Raw Details
        </template>
        <pre>{{ JSON.stringify(parsedDetails, null, 2) }}</pre>
      </b-popover>
    </div>
  </div>
  <div v-else>
    {{ parsedDetails }}
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FlightEventDetails extends Vue {
  @Prop() details!: any; // Prop passed from parent
  @Prop() event!: any;

  showDetails: boolean = false; // Add showDetails property
  popoverShow: boolean = false;

  // Method to toggle the popover visibility
  togglePopover() {
    this.popoverShow = !this.popoverShow;
  }

  // Computed property to parse details
  get parsedDetails() {
    try {
      // Parse details if it's a string; otherwise, return it as is
      return typeof this.details === 'string' ? JSON.parse(this.details) : this.details;
    } catch (e) {
      console.error('Error parsing details:', e);
      return null; // Return null if parsing fails
    }
  }
}
</script>
