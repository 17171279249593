<template>
  <div class="text-center">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3 class="m-0">Recommended Services</h3>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <div class="container">
        Airframes uses and recommends the following services for your own project, service,
        or business. Some of these links are referral links, so by signing up we will receive
        some form of commission to benefit the project and community, but we highly recommend
        them irregardless.
      </div>
    </div>
    <div class="mb-4 p-4 border-bottom">
      <h3>Services</h3>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://hetzner.cloud/?ref=H3LcFsk8C5Ei" target="_blank">
          <img src="https://pbs.twimg.com/profile_images/1168782761102139392/AP3o9UsW_400x400.jpg"
            width="200" height="200" class="mb-2" alt="Airplanes.Live Logo">
          <div>Hetzner</div>
        </a>
        <p>Affordable Server Hosting</p>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://zuffix.com" target="_blank">
          <img src="https://cdnassets.com/ui/resellerdata/1230000_1259999/1233151/supersite2/supersite/themes/EliteGreen-ResponsiveTheme/images/logo.gif" width=200 height=200 alt="Zuffix Logo" class="p-4 mb-2" style="object-fit: contain;">
          <div>Zuffix</div>
        </a>
        <p>Affordable Domain Names</p>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://shopify.pxf.io/6yy7xK" target="_blank">
          <img src="https://logosandtypes.com/wp-content/uploads/2020/11/shopify.svg"
            width=200 height=200 class="p-4 mb-2" style="object-fit: fill;"
            alt="Shopify Logo">
          <div>Shopify</div>
        </a>
        <p>Your own store</p>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://openphone.com" target="_blank">
          <img src="https://images.dialerhq.com/svg/openphone.svg"
            width=200 height=200 class="p-4 mb-2" style="object-fit: fill;"
            alt="OpenPhone Logo">
          <div>OpenPhone</div>
        </a>
        <p>Easy Telecommunications</p>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://wasabi.com" target="_blank">
          <img src="https://cdn.document360.io/bef0a1ea-7768-4d5a-b520-c4fe2f7fafad/Images/Documentation/wasabi_symbol.png"
            width=200 height=200 class="p-4 mb-2" style="object-fit: fill;"
            alt="Wasabi Logo">
          <div>Wasabi</div>
        </a>
        <p>Cheaper than Amazon S3</p>
      </div>
      <div class="d-inline-block p-4 m-2 border partner">
        <a href="https://mercury.com/r/airframes" target="_blank">
          <img src="https://static.otta.com/uploads/images/company-favicons/2225-rn85spiLfQyudDD1J0f9T2Rn6eSnP_5gyDaHX0wfkL4.png"
            width=200 height=200 class="p-4 mb-2" style="object-fit: fill;"
            alt="Mercury Logo">
          <div>Mercury</div>
        </a>
        <p>Free Business Banking</p>
      </div>


    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Partners extends Vue {
}
</script>

<style scoped>
.partner:hover {
  border-color: #ccc !important;
}
</style>
