<template>
  <div class="home">
    <div class="p-4 bg-white">
      <div class="container">
        <div class="d-flex justify-content-center">
          <div class="w-75">
            <img
              src="/logotype-bw.svg"
              title="Airframes Logo"
              alt="Airframes logo"
              class="mt-4 mb-4 img-fluid">
            <h1 class="mt-4 mb-4">Welcome!</h1>
            <p class="text-left">
              Airframes is a transportation (aviation, marine, etc) data aggregation service that
              receives ACARS, VDL, HFDL, SATCOM, and AIS data from volunteers around the world.
              This is similar to other efforts to
              collect, process, and display aircraft data like ADS-B, but with a focus on more
              interesting information, such as diagnostic, maintenance, and operational messages.
            </p>
            <p class="text-left">
              It is under <strong>very active
              development</strong>
              and you will notice changes from day to day. Also, issues are expected. Please report
              them on the <a href="https://discord.gg/airframes" target="_blank">Discord</a> server.
            </p>
            <div class="mb-4 text-left">
              Contributing your feed allows us to make ground developing new decoders and make
              important statistical observations. It also benefits users of the service so that they
              can see more about flights as they traverse covered territories.
            </div>
            <div class="text-center">
              <router-link to="/about" class="btn btn-primary">
                Learn more about Airframes
              </router-link>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <div class="p-4">
      <div class="container">
        <h3 class="mb-4">
          Recent Live Messages
        </h3>
        <p>
          Examples of messages received by the community. This is only a fraction of the actual
          inbound flow.
        </p>
        <MessagesList
          :messages="messagesFiltered"
          :messagesCountMaximum="3"
          :enableActiveAirframes="false"
          :enableFilters="false"
          :enablePopover="false"
          style="overflow: hidden;"
          />
      </div>
    </div>
    <div class="p-4 bg-white">
      <div class="container">
        <div class="m-auto w-75">
          <h2 class="mb-4">Stats</h2>
          <p>
            From time to time we publish statistics about the service. These are not
            comprehensive, but they are interesting. We expect to do a more thorough
            analysis in the future.
          </p>
          <div class="card-deck">
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h5 m-0 mx-1"><del>2.15 billion</del></div>
                <div class="h3 m-0 mx-1">3.1 billion</div>
                historical messages
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h5 m-0 mx-1"><del>26.5 million</del></div>
                <div class="h3 m-0 mx-1">34.1 million</div>
                flights
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h5 m-0 mx-1"><del>558 thousand</del></div>
                <div class="h3 m-0 mx-1">569 thousand</div>
                airframes
              </div>
            </div>
          </div>
          <div class="card-deck">
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h3 m-0 mx-1">6.2 thousand</div>
                airlines
              </div>
            </div>
          </div>
          <div class="card-deck">
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h3 m-0 mx-1">72 thousand</div>
                airports
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h5 m-0 mx-1"><del>730</del></div>
                <div class="h3 m-0 mx-1">870</div>
                users
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body">
                More than
                <div class="h5 m-0 mx-1"><del>305</del></div>
                <div class="h3 m-0 mx-1">345</div>
                active feeder stations
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import MessagesList from '@/components/MessagesList.vue';

@Component({
  components: {
    MessagesList,
  },
})
export default class Home extends Vue {
  messagesFiltered = [];

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'prependNewLiveMessages') {
        this.messagesFiltered = state.messagesLive.slice(0, 3);
      }
    });
  }
}
</script>
