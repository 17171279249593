<template>
  <div>
    <div id="nav" class="mt-0 pt-3 pb-3 border-bottom">
      <h3>Airframes</h3>
      <router-link :to="`/airframes/${$route.params.id}`">
        Airframe {{ airframe ? airframe.tail : 'Loading...' }}
      </router-link>
    </div>

    <div v-if="fetchingAirframe" class="m-4 p-4 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="noAirframeFound" class="m-4 p-4 text-center">
      <h1>404</h1>
      <h2>Airframe Not Found</h2>
    </div>

    <div v-if="airframe" class="mt-4 container text-left">
      <table class="table mb-4">
        <tr class="bg-dark text-light">
          <th>ID</th>
          <th>Tail</th>
          <th>ICAO</th>
          <th>IATA</th>
          <th>Manufacturer</th>
          <th>Model</th>
          <th>Owner</th>
        </tr>
        <tr class="bg-light">
          <td>{{ airframe.id }}</td>
          <td>{{ airframe.tail }}</td>
          <td>{{ airframe.icao }}</td>
          <td>{{ airframe.iata }}</td>
          <td>{{ airframe.manufacturer }}</td>
          <td>{{ airframe.model }}</td>
          <td>{{ airframe.owner }}</td>
        </tr>
      </table>
      <h5>Flights ({{ airframe.flights ? airframe.flights.length : 0 }})</h5>
      <div v-if="fetchingAirframe" class="m-4 p-4 text-left">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <table v-else-if="airframe.flights && airframe.flights.length > 0" class="mb-4 table small">
        <tr class="bg-dark text-light">
          <th class="text-left">Flight Number</th>
          <th class="text-left">Timestamp</th>
          <th class="text-left">Departing Airport</th>
          <th class="text-left">Destination Airport</th>
          <th class="text-left">Messages Count</th>
          <th class="text-right">Status</th>
        </tr>
        <tr v-for="flight in airframe.flights"
          :key="`ad-flight-${flight.id}`"
          >
          <td class="text-left">
            <router-link :to="`/flights/${flight.id}`">
              {{ flight.flight }}
            </router-link>
          </td>
          <td class="text-left">
            <span :title="flight.updatedAt">
              {{ flight.updatedAt | moment("MMM DD YYYY, HH:mm:ss [GMT]ZZ") }}
            </span>
          </td>
          <td>
            {{ flight.departingAirport }}
          </td>
          <td>
            {{ flight.destinationAirport }}
          </td>
          <td class="text-right">{{ flight.messagesCount }}</td>
          <td class="text-right">
            {{ textFormatter.humanize(flight.status) }}
          </td>
        </tr>
      </table>
      <div v-else>
        <p>No flights found.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';
import { TextFormatter } from '@/utilities/text_formatter';

Vue.use(VueAxios, axios);

@Component({
  components: {},
})
export default class AirframeDetail extends Vue {
  airframe: any = null;

  fetchingAirframe = false;

  errorFetchingAirframe = false;

  errorFetchingAirframeMessage = '';

  noAirframeFound = false;

  textFormatter = TextFormatter;

  mounted() {
    this.airframe = null;
    this.refresh();
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    if (newValue.params.id && newValue.params.id !== oldValue.params.id) {
      this.airframe = null;
      this.refresh();
    }
  }

  refresh() {
    if (this.$route.params) {
      this.fetchingAirframe = true;
      console.log('Fetching airframe detail...');
      Vue.axios.get(`${this.$store.state.apiServerBaseUrl}/airframes/${this.$route.params.id}`).then((response) => {
        console.log('Fetched airframe detail.');
        console.log(response.data);
        const airframe = response.data;
        airframe.flights = airframe.flights || [];
        airframe.flights = airframe.flights.sort((a: any, b: any) => { // eslint-disable-line max-len, arrow-body-style
          return a.updatedAt < b.updatedAt ? 1 : -1;
        });
        this.airframe = airframe;
        this.fetchingAirframe = false;
      }).catch((error) => {
        console.error('Error fetching airframe detail.');
        console.error(error);
        this.errorFetchingAirframe = true;
        this.errorFetchingAirframeMessage = error;
        this.fetchingAirframe = false;
      });
    }
  }
}
</script>
