import Vue from 'vue';
import VueRouter from 'vue-router';

import About from '@/views/About.vue';
import AcarsRouterRedirect from '@/views/AcarsRouterRedirect.vue';
import AdminSockets from '@/views/admin/AdminSockets.vue';
import AdminStats from '@/views/admin/AdminStats.vue';
import AdminStatsAirlines from '@/views/admin/stats/AdminStatsAirlines.vue';
import AdminStatsAirframes from '@/views/admin/stats/AdminStatsAirframes.vue';
import AdminStatsErrors from '@/views/admin/stats/AdminStatsErrors.vue';
import AdminStatsFlights from '@/views/admin/stats/AdminStatsFlights.vue';
import AdminStatsFrequencies from '@/views/admin/stats/AdminStatsFrequencies.vue';
import AdminStatsMessageLabels from '@/views/admin/stats/AdminStatsMessageLabels.vue';
import AdminStatsStations from '@/views/admin/stats/AdminStatsStations.vue';
import Airframes from '@/views/Airframes.vue';
import AirframeDetail from '@/views/AirframeDetail.vue';
import Airlines from '@/views/Airlines.vue';
import Airports from '@/views/Airports.vue';
import AirportDetail from '@/views/AirportDetail.vue';
import ConfirmUser from '@/views/auth/ConfirmUser.vue';
import EditUserStation from '@/views/user/EditUserStation.vue';
import FAQ from '@/views/FAQ.vue';
import FlightDetail from '@/views/FlightDetail.vue';
import Flights from '@/views/Flights.vue';
import HFDL from '@/views/HFDL.vue';
import IngestStats from '@/views/IngestStats.vue';
import Home from '@/views/Home.vue';
import Leaderboard from '@/views/Leaderboard.vue';
import Login from '@/views/auth/Login.vue';
import MessageDetail from '@/views/MessageDetail.vue';
import Messages from '@/views/Messages.vue';
import MessagesHistorical from '@/views/MessagesHistorical.vue';
import MessagesLive from '@/views/MessagesLive.vue';
import MyIp from '@/views/MyIp.vue';
import Partners from '@/views/Partners.vue';
import Register from '@/views/auth/Register.vue';
import ResendConfirmation from '@/views/auth/ResendConfirmation.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import ResetPasswordConfirm from '@/views/auth/ResetPasswordConfirm.vue';
import Services from '@/views/Services.vue';
import Stations from '@/views/Stations.vue';
import StationsByCountry from '@/views/StationsByCountry.vue';
import StationDetail from '@/views/StationDetail.vue';
import Users from '@/views/Users.vue';
import UserDashboard from '@/views/user/UserDashboard.vue';
import UserSettings from '@/views/user/UserSettings.vue';
import UserSettingsIntegrations from '@/views/user/settings/UserSettingsIntegrations.vue';
import UserSettingsNotifications from '@/views/user/settings/UserSettingsNotifications.vue';
import UserSettingsProfile from '@/views/user/settings/UserSettingsProfile.vue';
import UserProfile from '@/views/UserProfile.vue';
import UserStation from '@/views/user/UserStation.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Airframes - Community sourced realtime aircraft data via ACARS, VDL, SATCOM and ADS-C',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About Airframes',
    },
  },
  {
    path: '/airlines',
    name: 'airlines',
    component: Airlines,
    meta: {
      title: 'Airlines',
    },
  },
  {
    path: '/airports',
    name: 'airports',
    component: Airports,
    meta: {
      title: 'Airports',
    },
  },
  {
    path: '/airports/:id',
    name: 'airport_detail',
    component: AirportDetail,
    meta: {
      title: 'Airport Detail',
    }
  },
  {
    path: '/auth/confirm/:email/:token',
    name: 'auth_confirm',
    component: ConfirmUser,
    meta: {
      title: 'User Confirmation',
    },
  },
  {
    path: '/auth/login',
    name: 'auth_login',
    component: Login,
    meta: { title: 'Login to Airframes', layout: 'no-navigation' },
  },
  {
    path: '/auth/register',
    name: 'auth_register',
    component: Register,
    meta: { title: 'Create an Airframes Account', layout: 'no-navigation' },
  },
  {
    path: '/auth/resend-confirmation',
    name: 'auth_resend_confirmation',
    component: ResendConfirmation,
    meta: { title: 'Resend Confirmation', layout: 'no-navigation' },
  },
  {
    path: '/auth/reset-password',
    name: 'auth_reset_password',
    component: ResetPassword,
    meta: { title: 'Reset Password', layout: 'no-navigation' },
  },
  {
    path: '/auth/reset-password/:email/:token',
    name: 'auth_reset_password_token',
    component: ResetPasswordConfirm,
    meta: { title: 'Reset Password Confirmation', layout: 'no-navigation' },
  },
  {
    path: '/admin/sockets',
    name: 'admin_sockets',
    component: AdminSockets,
  },
  {
    path: '/admin/stats',
    name: 'admin_stats',
    component: AdminStats,
    meta: {
      title: 'Admin Stats',
    },
  },
  {
    path: '/admin/stats/airlines',
    name: 'admin_stats_airlines',
    component: AdminStatsAirlines,
  },
  {
    path: '/admin/stats/airframes',
    name: 'admin_stats_airframes',
    component: AdminStatsAirframes,
  },
  {
    path: '/admin/stats/errors',
    name: 'admin_stats_errors',
    component: AdminStatsErrors,
  },
  {
    path: '/admin/stats/flights',
    name: 'admin_stats_flights',
    component: AdminStatsFlights,
  },
  {
    path: '/admin/stats/frequencies',
    name: 'admin_stats_frequencies',
    component: AdminStatsFrequencies,
  },
  {
    path: '/admin/stats/labels',
    name: 'admin_stats_labels',
    component: AdminStatsMessageLabels,
  },
  {
    path: '/admin/stats/stations',
    name: 'admin_stats_stations',
    component: AdminStatsStations,
  },
  {
    path: '/airframes',
    name: 'airframes',
    component: Airframes,
    meta: {
      title: 'Known Airframes',
    },
  },
  {
    path: '/airframes/:id',
    name: 'airframe',
    component: AirframeDetail,
    meta: {
      title: 'Airframe Detail',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ,
    meta: {
      title: 'Frequently Asked Questions',
    },
  },
  {
    path: '/flights',
    name: 'flights',
    component: Flights,
    meta: {
      title: 'Active Flights',
    },
  },
  {
    path: '/flights/:id',
    name: 'flight',
    component: FlightDetail,
    meta: {
      title: 'Flight Detail',
    },
  },
  {
    path: '/hfdl',
    name: 'hfdl',
    component: HFDL,
    meta: {
      title: 'HFDL',
    },
  },
  {
    path: '/ingest-stats',
    name: 'ingest_stats',
    component: IngestStats,
    meta: {
      title: 'Ingest Stats',
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      title: 'Messages',
    },
  },
  {
    path: '/messages/ar/:id',
    name: 'acars_router_message_redirect',
    component: AcarsRouterRedirect,
    meta: {
      title: 'AcarsRouter Message Redirect',
    },
  },
  {
    path: '/messages/historical',
    name: 'messages_historical',
    component: MessagesHistorical,
    props: true,
    meta: {
      title: 'Historical Messages',
    },
  },
  {
    path: '/messages/live',
    name: 'messages_live',
    component: MessagesLive,
    meta: {
      title: 'Live Messages',
    },
  },
  {
    path: '/messages/:id',
    name: 'message_detail',
    component: MessageDetail,
    meta: {
      title: 'Message Detail',
    },
  },
  {
    path: '/myip',
    name: 'myip',
    component: MyIp,
    meta: {
      title: 'My IP',
    },
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners,
    meta: {
      title: 'Partners',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      title: 'Services',
    },
  },
  {
    path: '/stations',
    name: 'stations',
    component: Stations,
    meta: {
      title: 'Feeder Stations',
    },
  },
  {
    path: '/stations/by-country',
    name: 'stations_by_country',
    component: StationsByCountry,
    meta: {
      title: 'Stations by Country || Airframes',
    },
  },
  {
    path: '/stations/leaderboard',
    name: 'leaderboard',
    component: Leaderboard,
    meta: {
      title: 'Leaderboard',
    },
  },
  {
    path: '/stations/leaderboard/:date',
    name: 'leaderboard_date',
    component: Leaderboard,
    meta: {
      title: 'Leaderboard for Date',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Users',
    },
  },
  {
    path: '/users/:username',
    name: 'user_profile',
    component: UserProfile,
    meta: {
      title: 'User Profile',
    },
  },
  {
    path: '/user/dashboard',
    name: 'user_dashboard',
    component: UserDashboard,
    meta: {
      title: 'Your Dashboard',
    },
  },
  {
    path: '/user/settings',
    name: 'user_settings',
    component: UserSettings,
    meta: {
      title: 'User Settings',
    },
  },
  {
    path: '/user/settings/integrations',
    name: 'user_settings_integrations',
    component: UserSettingsIntegrations,
    meta: {
      title: 'User Settings | Integrations',
    },
  },
  {
    path: '/user/settings/notifications',
    name: 'user_settings_notifications',
    component: UserSettingsNotifications,
    meta: {
      title: 'User Settings | Notifications',
    },
  },
  {
    path: '/user/settings/profile',
    name: 'user_settings_profile',
    component: UserSettingsProfile,
    meta: {
      title: 'User Settings | Public Profile',
    },
  },
  {
    path: '/user/station/:id',
    name: 'user_station',
    component: UserStation,
    meta: {
      title: 'Station',
    },
  },
  {
    path: '/user/station/:id/edit',
    name: 'edit_user_station',
    component: EditUserStation,
    meta: {
      title: 'Edit Station',
    },
  },
  {
    path: '/stations/:ident',
    name: 'station_detail',
    component: StationDetail,
    meta: {
      title: 'Station Detail',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's
  // will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  next();
});

export default router;
