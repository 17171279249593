<template>
  <div class="home">
    <div id="nav" class="mt-0 pt-3 pb-3 bg-white border-bottom">
      <h3>Stations</h3>
      <router-link to="/stations">Stations</router-link>
      <span v-if="$route.params.ident">
        | {{ this.$route.params.ident }}
      </span>
    </div>
    <div v-if="fetchingStation" class="m-4 p-4 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="noStationFound" class="m-4 p-4 text-center">
      <h1>404</h1>
      <h2>Station Not Found</h2>
    </div>
    <div v-if="station" class="about text-center">
      <div class="mb-4 p-4 border-bottom">
        <div class="container text-left">
          <h3 class="mt-4 mb-4 text-left">
            {{ station.ident }}
            <div class="pull-right" style="margin-top: -6px;">
              <small class="badge text-white p-2 pl-2 pr-2" :class="stationStatusClass()">
                {{ textFormatter.humanize(station.status) }}
              </small>
            </div>
          </h3>
          <p class="mb-4">
            {{ station.description }}
          </p>

          <h4 class="mt-0 mb-4">Specifications</h4>
          <div class="row">
            <div class="col-5">
              <table class="table table-hover mb-0 bg-white">
                <tr>
                  <td>ID</td>
                  <td>{{ station.id }}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{{ station.sourceType ? station.sourceType.toUpperCase() : "" }}</td>
                </tr>
                <tr>
                  <td>Owner</td>
                  <td>
                    <a
                      v-if="station.user"
                      :href="`/users/${station.user.username}`"
                      >
                      <img
                        :src="station.user.gravatarUrl"
                        :alt="`Avatar for ${station.user.username}`"
                        class="rounded-circle align-middle"
                        width="20"
                        height="20"
                        />
                      {{ station.user.username }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>
                    <img
                      :src="station.flagImageUrl"
                      width="30" height="20" :title="station.countryName"
                      alt="Country Flag"
                      class="mr-1"
                      />
                    {{ station.countryName }}
                  </td>
                </tr>
                <tr>
                  <td>Nearest Airport</td>
                  <td>{{ station.nearestAirportIcao || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{{ station.latitude || 'Unknown' }}, {{ station.longitude || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Altitude</td>
                  <td>
                    {{
                      station.altitude ?
                        `${textFormatter.intToHuman(station.altitude)} ft ASL` : 'Unknown'
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Platform</td>
                  <td>{{ station.systemPlatform || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Operating System</td>
                  <td>{{ station.systemOS || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>SDR</td>
                  <td>{{ station.equipmentSdr || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Filters</td>
                  <td>{{ station.equipmentFilters || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Antenna</td>
                  <td>{{ station.equipmentAntenna || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Feeder Client</td>
                  <td>{{ station.sourceApplication || 'Unknown' }}</td>
                </tr>
                <tr>
                  <td>Messages</td>
                  <td>
                    <span v-if="station.messagesCount" class="badge badge-pill badge-info">
                      {{ textFormatter.intToHuman(station.messagesCount) }}
                    </span>
                    <span v-else>0</span>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-7">
              <Map
                :coordinates="stationCoordinates()"
                class="border"
                style="height: 100%; width: 100%;"
                />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 p-4 border-bottom">
        <div class="container text-left">
          <h4 class="mb-2">Recent Messages</h4>
          <p class="mb-4">
            The 10 most recent messages received from this station.
          </p>
          <div v-if="recentMessages && recentMessages.length > 0">
            <MessagesSimpleTable :messages="recentMessages" />
          </div>
          <h5 v-else-if="loadingMessages" class="text-muted">Loading...</h5>
          <h5 v-else class="text-muted">No recent messages found.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TextFormatter } from '@/utilities/text_formatter';

import Map from '@/components/shared/Map.vue';
import MessagesSimpleTable from '@/components/messages/MessagesSimpleTable.vue';

@Component({
  components: {
    Map,
    MessagesSimpleTable,
  },
})
export default class StationDetail extends Vue {
  fetchInterval?: number;

  fetchingStation = false;

  loadingMessages = false;

  noStationFound = false;

  recentMessages: any[] = [];

  station: any = null;

  textFormatter = TextFormatter;

  mounted() {
    this.fetchStation();
    this.clearIntervals();
    this.fetchInterval = window.setInterval(() => {
      this.fetchStation();
    }, 10000);
  }

  clearIntervals() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
    }
  }

  @Watch('$route')
  onPropertyChanged(newValue: any, oldValue: any) {
    this.station = null;
    this.recentMessages = [];
    if (newValue.params.ident && newValue.params.ident !== oldValue.params.ident) {
      this.fetchStation();
    }
  }

  fetchStation() {
    this.fetchingStation = true;
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/stations/ident/${this.$route.params.ident}`,
      method: 'GET',
    }).then((response) => {
      this.station = response.data;
      if (this.station) {
        this.fetchRecentAircraftMessagesForStation();
      } else {
        this.noStationFound = true;
      }
      this.fetchingStation = false;
    });
  }

  fetchRecentAircraftMessagesForStation() {
    this.loadingMessages = true;
    Vue.axios({
      url: `${this.$store.state.apiServerBaseUrl}/messages?station_ids=${this.station.id}&limit=10`,
      method: 'GET',
    }).then((response) => {
      this.recentMessages = response.data;
      this.loadingMessages = false;
    });
  }

  fetchRecentMarineMessagesForStation() {
    this.loadingMessages = true;
    Vue.axios({
      url: `https://toad.airframes.io/v1/marine/messages?station_ids=${this.station.id}&limit=10`,
      method: 'GET',
    }).then((response) => {
      this.recentMessages = response.data;
      this.loadingMessages = false;
    });
  }

  stationCoordinates() {
    const coords = { latitude: this.station.latitude, longitude: this.station.longitude };
    return coords;
  }

  stationStatusClass() {
    const statusClass = {
      'badge-success': this.station.status === 'active',
      'badge-warning': this.station.status === 'pending-activation',
      'badge-danger': this.station.status === 'inactive',
    };
    return statusClass;
  }

  beforeDestroy() {
    this.clearIntervals();
  }

  beforeUnmount() {
    this.clearIntervals();
  }

  destroyed() {
    this.clearIntervals();
  }

  unmounted() {
    this.clearIntervals();
  }
}
</script>
